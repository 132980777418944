// recomendaciones.js
import React from 'react';
import { motion } from 'framer-motion';
import './Recomendaciones.css';
import Maintenance from '../Maintenance';
// En las descripcion habra un texto emocilanl , alineado con el tutulo de cada event
const recommendationsData = [
  {
    title: 'Calzado cómodo',
    description: 'Para las mujeres: Les recomendamos usar tacones anchos y cómodos para evitar hundirse o enterrarse en la tierra durante el evento al aire libre. Queremos que todos disfruten cada momento sin preocupaciones, ¡así que ven preparada para disfrutar al máximo!',
  },
  {
    title: 'Protección solar',
    description: '¡Prepárate para el sol! Asegúrate de aplicar protector solar antes de llegar al lugar. Queremos compartir este día especial bajo un cielo radiante, así que cuidémonos para que todos luzcamos resplandecientes y llenos de energía positiva.',
  },
  {
    title: 'Repelente contra mosquitos',
    description: 'Debido a la ubicación al aire libre, recomendamos que todos traigan consigo repelente contra mosquitos. Queremos asegurarnos de que su experiencia sea lo más cómoda posible y libre de picaduras indeseadas. ¡La mejor defensa contra los mosquitos es estar preparado!',
  },
  {
    title: 'Regalos con Amor',
    description: 'La verdad es que su presencia es el mejor regalo que podríamos recibir. Su apoyo y amor son más valiosos que cualquier obsequio material. Si desean contribuir de alguna manera, sería maravilloso recibir su ayuda económica para poder cumplir nuestros sueños juntos. Su generosidad será recordada con gratitud eterna.',
  },
];

const Recomendaciones = () => {
  const fadeInVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <motion.div
      className="recomendaciones-container"
      initial="hidden"
      animate="visible"
      variants={fadeInVariants}
    >
      <h2 className="recomendaciones-titulo caveat-xxl">Recomendaciones</h2>
      {recommendationsData.map((recommendatio, index) => (
        <motion.div
          key={index}
          className="evento"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: index * 0.2 }}
        >

          <div className="titulo-evento caveat-xl">{recommendatio.title}</div>
          <div className="descripcion-evento normal-text">{recommendatio.description}</div>
          {index < recommendationsData.length - 1 && <hr className="linea-divisoria" />}
        </motion.div>
      ))}
    </motion.div>
  );
};

export default Recomendaciones;
