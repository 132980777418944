// Maps.js
import React, { useState } from 'react';
import { motion } from 'framer-motion';

const Maps = () => {
  const [loading, setLoading] = useState(true);

  const mapURL =
    'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1dYOUR_LATITUDE!2dYOUR_LONGITUDE!3dYOUR_ZOOM_LEVEL!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xaa47bb623e175dba!2sBambusa%208!5e0!3m2!1sen!2sus!4vYOUR_EMBED_API_KEY';

  const handleLoad = () => {
    setLoading(false);
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      style={{
        position: 'relative',
        width: '100%',
        height: '400px',
        overflow: 'hidden',
      }}
    >
      {loading && (
        <div className="loading-overlay-map">
          <div className="loading-spinner-map"></div>
        </div>
      )}

      <iframe
        title="Google Map"
        width="100%"
        height="100%"
        frameBorder="0"
        style={{ border: 0 }}
        src={mapURL}
        allowFullScreen
        onLoad={handleLoad}
      ></iframe>
    </motion.div>
  );
};

export default Maps;
