import { useState } from "react";
import { Blurhash } from "react-blurhash";
import LazyLoad from "react-lazyload";
import styled from "styled-components";


const StyledBlurhash = styled(Blurhash)`
  z-index: -5;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;

const Placeholder = styled.div`
  display: ${({ hiddenPlaceholder }) => (hiddenPlaceholder ? "none" : "block")};
  transition: opacity 3s;
`;

const Image = styled.img`
  width: 100%;
  display: ${({ isLoaded }) => (isLoaded ? "block" : "none")};
  cursor: pointer;
  z-index: ;
  
`;

function OptimizedImage(props) {
  const { image, project, i, viewImage } = props;

  const [isLoaded, setLoaded] = useState(false);
  const [hiddenPlaceholder, setHiddenPlaceholder] = useState(false);
  const [isLoadStarted, setLoadStarted] = useState(false);

  const handleLoad = () => {
    console.log("Loaded: ");
    // Aquí puedes realizar acciones adicionales cuando la imagen ha cargado completamente
    //timeout para que se vea el blurhash
    setTimeout(() => {
      setHiddenPlaceholder(true);
    }, 5000);
    setHiddenPlaceholder(true);
    setLoaded(true);
  };

  const handleLoadStarted = () => {
    console.log("Load started: ");
    setLoadStarted(true);
  };

  const url = `/images/${project.id}/${image.image_name}`;
  const placeholderUrl = `/images/${project.id}/placeholder.png`;


  return (
    <LazyLoad key={i} height={500} offset={50} once={true}>
      <Placeholder hiddenPlaceholder={hiddenPlaceholder}>
        <StyledBlurhash
          hash={image.blurHash}
          width={"100%"}
          height={image.height ? image.height : 500}
          resolutionX={32}
          resolutionY={32}
          punch={1}
        />
      </Placeholder>
      <Image
        isLoaded={isLoaded}
        src={url}
        onLoad={handleLoad}
        onLoadStart={handleLoadStarted}
        alt={placeholderUrl}
        onClick={() => viewImage(`/images/${project.id}/${image.image_name}`, i)}
      />
    </LazyLoad>
  );
}

export { OptimizedImage };
