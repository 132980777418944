import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import LazyLoad from 'react-lazyload';
import { FaAngleLeft } from 'react-icons/fa';
import { FaAngleRight } from 'react-icons/fa';
import { RxCross1 } from 'react-icons/rx';
import { Blurhash } from 'react-blurhash'; // Importa Blurhash
import './Gallery.css';
import { OptimizedImage } from '../optimizedImage';




export const Gallery = ({ project, images }) => {
  const [data, setData] = useState({ img: '', i: 0 });
  const [galleryRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: '-100px 0px',
  });
  const textAnimation = useAnimation();

  const viewImage = (img, i) => {
    setData({ img, i });
    console.log('img', i);
    document.body.classList.add('no-scroll');
  };

  const imgAction = (action) => {
    let i = data.i;

    console.log('action', i)
    if (action === 'next-img') {
      if (i === images.length - 1) i = -1;
      setData({ img: `/images/${project.id}/${images[i + 1].image_name}`, i: i + 1 });
    }
    if (action === 'previous-img') {
      if (i === 0) i = images.length;
      setData({ img: `/images/${project.id}/${images[i - 1].image_name}`, i: i - 1 });
    }
    if (!action) {
      setData({ img: '', i: 0 });
    }
  };

  useEffect(() => {
    if (inView) {
      textAnimation.start('visible');
    }
  }, [inView, textAnimation]);

  const textVariants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
      },
    },
  };

  useEffect(() => {
    if (data.img === '' || data.img === undefined) document.body.classList.remove('no-scroll');
  }, [data]);

  return (
    <>
      <AnimatePresence>
        {data.img && (
          <motion.div
            className='image-open'
            initial={{ y: '-100%', opacity: 0 }}
            animate={{ y: '0%', opacity: 1 }}
            exit={{ x: '-100%', opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <span onClick={() => imgAction()} className='close-btn'>
              <RxCross1 />
            </span>
            <div className='gallery-left' onClick={() => imgAction('previous-img')}>
              <motion.button className='btn-left'>
                <FaAngleLeft />
              </motion.button>
            </div>
            <div className='gallery-right' onClick={() => imgAction('next-img')}>
              <motion.button className='btn-right'>
                <FaAngleRight />
              </motion.button>
            </div>
            <motion.img
              alt=''
              src={data.img}
              key={data.i}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
            />
          </motion.div>
        )}
      </AnimatePresence>
      <motion.div
        className='gallery'
        ref={galleryRef}
        initial='hidden'
        animate={textAnimation}
        variants={textVariants}
      >
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
          <Masonry gutter='10px'>
            {/*images.map((image, i) => (
              <LazyLoad key={i} height={200} offset={100}>
                <Blurhash hash={image.blurHash} width='100%' height='100%' />
                <img
                  src={`/images/${project.id}/${image.image_name}`}
                  style={{ width: '100%', display: 'block', cursor: 'pointer' }}
                  alt=''
                  onClick={() => viewImage(`/images/${project.id}/${image.image_name}`, i)}
                />
              </LazyLoad>
            ))*/}
            {images.map((image, i) => (
              <OptimizedImage i={i} image={image} project={project} viewImage={viewImage} />
            ))}

          </Masonry>
        </ResponsiveMasonry>
      </motion.div>
    </>
  );
};
