// Sitio.js
import React from 'react';
import { motion } from 'framer-motion';
import Maps from '../maps';
import './sitio.css';

export const Sitio = () => {
  return (
    <div className='page'>
      <div className='sitio-description'>
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
          className='font-title caveat'
        >
          Sitio
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Maps />
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className='location-info'
        >
          <br />
          <span className='center-text caveat color-main message-title'>Bambusa 8 - Via la Siria</span>
          <span className=' normal-parrafo  texto-con-espaciado'>Cuando llegues al lugar, dirigete a una escuela al lado izquierdo de Bambusa,  alli podras entrar y parquear tu vehículo.</span>
        </motion.div>
      </div>
    </div>
  );
};
