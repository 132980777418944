import React, { useContext, useState, useRef, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import LogoWhite from '../../assets/images/logo_white.png';
import LogoBlack from '../../assets/images/logo_black.png';
import { ContextProvider } from '../../contexts/ContextProvider';
//import { FaBars, FaTimes } from "react-icons/fa";
import './HeaderNav.css';
import { BurguerButton } from './BurguerButton/BurguerButton';
import { useTranslation } from 'react-i18next';

export const HeaderNav = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const [typeNav, setTypeNav] = useState(false);
  const { modePage, setModePage, currentUrl } = useContext(ContextProvider);
  const { t } = useTranslation();
  const navRef = useRef();

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const closeNavbar = () => {
    setShowNavbar(false);
  };

  const navClassName = ({ isActive }) => {
    let fontColor = modePage === "dark" ? 'font-light' : 'font-dark';
    let classActive = isActive ? 'active' : '';

    return `${fontColor} ${classActive}`;
  };


  useEffect(() => {
    /* Control Resize for responsive */
    const handleResize = () => {
      if (window.innerWidth > 966) {
        setShowNavbar(false);
      }
    };

    if (currentUrl === "home" || currentUrl === "project" || currentUrl === "confirmacion") setModePage('dark');
    else setModePage('light');

    window.addEventListener('resize', handleResize);

    /** Control Scroll*/
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      // Punto de referencia 0
      if (scrollPosition === 0) {
        // Acción cuando se alcanza el punto de referencia 1
        setTypeNav('');
        if (currentUrl === "home" || currentUrl === "project" || currentUrl === "confirmacion") setModePage('dark');
        else setModePage('light');
      }

      if (scrollPosition < 10 && scrollPosition > 0) {
        // Acción cuando se alcanza el punto de referencia 1
        setTypeNav('');
        if (currentUrl === "home" || currentUrl === "confirmacion") setModePage('dark')
        else setModePage('light');
      }

      // Punto de referencia 1
      if (scrollPosition > 10 && scrollPosition <= 200) {
        // Acción cuando se alcanza el punto de referencia 1
        if (currentUrl === "home" || currentUrl === "confirmacion") {
          setTypeNav('nav-mode-one dark');
          setModePage('dark');
        } else {
          setTypeNav('nav-mode-one');
          setModePage('light');
        }
      }

      // Punto de referencia 2
      if (scrollPosition > 200) {
        // Acción cuando se alcanza el punto de referencia 2
        setTypeNav('nav-mode-one dark');
        setModePage('dark');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUrl]);

  return (
    <header className={`nav-bar  ${typeNav ? typeNav : ''} `}>
      <div className='header'>
        <Link className='logo' to="/home">

          {modePage === "dark" ? <NavLink className='font-light' to="/home">  Marzo 16, 2024</NavLink> :
            <NavLink className='logo-font-dark' to="/home">  Marzo 16, 2024</NavLink>}
        </Link>
        <nav ref={navRef} className={`${showNavbar && 'active'}`}>
          <ul>
            <li>
              <NavLink
                to="/home"
                className={navClassName}
                onClick={closeNavbar} // Agregar onClick para cerrar el navbar
              >
                {t('header.home')}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/cronograma"
                className={navClassName}
                onClick={closeNavbar} // Agregar onClick para cerrar el navbar
              >
                {t('header.cronograma')}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/recomendaciones"
                className={navClassName}
                onClick={closeNavbar} // Agregar onClick para cerrar el navbar
              >
                {t('header.recomendaciones')}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/sitio"
                className={navClassName}
                onClick={closeNavbar} // Agregar onClick para cerrar el navbar
              >
                {t('header.sitio')}
              </NavLink>
            </li>
            {
              <li>
                <NavLink
                  to="/galeria"
                  className={navClassName}
                  onClick={closeNavbar} // Agregar onClick para cerrar el navbar
                >
                  {t('header.galeria')}
                </NavLink>
              </li>
            }
            <li>
              <NavLink
                to="/confirmacion"
                className={navClassName}
                onClick={closeNavbar} // Agregar onClick para cerrar el navbar
              >
                {t('header.confirmacion')}
              </NavLink>
            </li>
          </ul>
          {/*
          <button
            className="nav-btn nav-close-btn"
            onClick={handleShowNavbar}
          >
            <FaTimes />
          </button>*/}
        </nav>
        {/*
        <button
          className="nav-btn"
          onClick={handleShowNavbar}
        >
          <FaBars />
  </button>*/}
        <div className="nav2-btn">
          <BurguerButton modePage={modePage} showNavbar={showNavbar} handleShowNavbar={handleShowNavbar} />
        </div>
      </div>
    </header>
  );
};
