import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import './Inicio.css';
import { projectService } from '../../data/projectService';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { TfiAngleDown } from "react-icons/tfi";
import { BsChevronCompactDown } from "react-icons/bs";
import { MdArrowDropDownCircle } from 'react-icons/md';

import { useTranslation } from 'react-i18next';
import CountdownTimer from '../countDownTimer/CountdownTimer';
import { ContextProvider } from '../../contexts/ContextProvider';

export const Inicio = () => {
  const [project, setProject] = useState({});
  const params = useParams();
  const contentRef = useRef(null);
  const { t } = useTranslation();

  const { setCurrentUrl, setHistoryLoaded, historyLoaded } = useContext(ContextProvider);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    //validate if home is in history loaded , si no esta lo agrega
    if (historyLoaded.home === undefined) {
      setHistoryLoaded({ ...historyLoaded, home: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [textRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: '-100px 0px', // Ajusta el margen según sea necesario
  });
  const textAnimation = useAnimation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setProject(projectService.getProjectById('Project2'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inView) {
      textAnimation.start('visible');
    }
  }, [inView, textAnimation]);

  const textVariants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
      },
    },
  };

  const scrollToBottom = () => {
    window.scrollTo({
      //top: document.documentElement.scrollHeight,
      top: contentRef.current.offsetTop,
      behavior: 'smooth',
    });
  };


  /*
  const handleScroll = () => {
    const contentSection = contentRef.current;
    const rect = contentSection.getBoundingClientRect();
    const quarterScreenHeight = window.innerHeight / 4;
    const isVisible = rect.top < window.innerHeight - quarterScreenHeight && rect.bottom >= quarterScreenHeight;
    contentSection.classList.toggle('show', isVisible);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);*/

  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      transition={{ delay: 0, duration: 2 }}
      className="main-page"
    >
      <div className='page-project'>
        <div className={`title-project font-title`}>
          <div className="btn-scroll-down" onClick={scrollToBottom}>
            <h1 className='font-title'>LAU <span className='caveat caveat-xl'>y</span> NICO</h1>

          </div>

        </div>
        <div className="btn-scroll-down" onClick={scrollToBottom}>
          <div className="">
            <i className="fa fa-angle-down" aria-hidden="true"><BsChevronCompactDown /></i>
          </div>
        </div>
      </div>
      <section ref={contentRef} className={`content-project ${inView ? 'show' : ''}`}>
        <div className='project-description'>
          <div className='title'>
            <motion.div
              className={`font-title darks`}
              ref={textRef}
              initial="hidden"
              animate={textAnimation}
              variants={textVariants}
            >



              <div className='message-bienvenida center-text normal-text'>
                <span className='center-text caveat color-main message-title'>¡Bienvenidos al rincón donde comienza nuestra historia de amor eterno!</span><p>Estamos emocionados y rebosantes de felicidad al compartir la maravillosa noticia: <span className=' color-main '>¡Nos vamos a casar! </span> Este será un capítulo lleno de amor, complicidad y sueños compartidos, donde dos vidas  se unen para tejer un futuro juntos.</p><p>Nuestra historia de amor ha sido un viaje increíble, lleno de risas, aprendizajes y momentos inolvidables. </p> <p>Este día mágico será la culminación de todo lo que hemos construido juntos hasta ahora y el inicio de lo que se vendra; no podemos esperar para compartirlo con nuestros seres queridos, aquellos que han sido testigos de nuestro crecimiento y han compartido su amor incondicional.</p>
              </div>
              <span className='center-text higth-text'>Marzo 16, 2024</span>
              <span className='center-text medium-text'>03:00 PM</span>
              <CountdownTimer />
              <Link to="/confirmacion" className='button-confirmation center-text none-decoration'>
                <button type='button' aria-label='Omitir confirmación'>
                  Confirmacion
                </button>
              </Link>
            </motion.div>
            <br />
          </div>

        </div>

      </section>
    </motion.div>
  );
};
