import React, { useContext, useEffect, useState } from 'react';
import './Confirmacion.css';
import { motion } from 'framer-motion';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { ContextProvider } from '../../contexts/ContextProvider';

export const Confirmacion = () => {
  const [name, setName] = useState('');
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [guestList, setGuestList] = useState([]);
  const [familyMembers, setFamilyMembers] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAttending, setIsAttending] = useState(null);
  const [email, setEmail] = useState('');
  const [song, setSong] = useState('');
  const [message, setMessage] = useState('');
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const { setCurrentUrl } = useContext(ContextProvider);
  const [isLoading, setIsLoading] = useState(false);
  const [searchByNameError, setSearchByNameError] = useState('');

  useEffect(() => {
    setCurrentUrl("confirmacion");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchByName = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/searchByName?name=${name}`);
      if (response.data.data.length <= 0) {
        setSearchByNameError('No encontrado');
        setIsLoading(false);
        return;
      }

      setGuestList(response.data.data);
      setSearchByNameError('');
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setSearchByNameError('No encontrado');
      console.error('Error al buscar por nombre:', error);
    }
  };

  const handleContinue = async () => {
    try {
      setIsLoading(true);
      const selectedPersonId = selectedPerson ? selectedPerson.id : null;

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/familyMembers?id=${selectedPersonId}`);
      let familyMembers = response.data.data;

      setFamilyMembers(familyMembers);

      if (familyMembers.length > 0) {
        // set email, song, message y isAttending
        const familyMember = familyMembers[0];
        setEmail(familyMember.email);
        setSong(familyMember.pregunta1);
        setMessage(familyMember.pregunta2);
        setIsAttending(familyMember.estado);
      }

      setCurrentIndex(0);
      setConfirmationMessage('');
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error al continuar con la confirmación:', error);
    }
  };

  const handleSkip = () => {
    // Pasa al siguiente miembro sin confirmar
    setCurrentIndex(currentIndex + 1);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // set email, song, message y isAttending
    if (currentIndex + 1 < familyMembers.length) {
      const familyMember = familyMembers[currentIndex + 1];
      setEmail(familyMember.email);
      setSong(familyMember.pregunta1);
      setMessage(familyMember.pregunta2);
      setIsAttending(familyMember.estado);
    }

    // Si ha llegado al final, reinicia el proceso
    if (currentIndex + 1 === familyMembers.length) {
      setFamilyMembers([]);
      setCurrentIndex(0);
      setSelectedPerson(null);
      setName('');
      setGuestList([]);
    }
  };

  const handleConfirmation = async () => {
    try {
      setIsLoading(true);
      // Validación: Asegurarse de que se haya seleccionado una opción de confirmación
      if (isAttending === null || isAttending === 'pendiente') {
        alert('Por favor, selecciona tu confirmación antes de continuar.');
        setIsLoading(false);
        return;
      }
      const currentGuest = familyMembers[currentIndex];
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/confirmInvitado`,
        {
          id: currentGuest.id,
          email,
          estado: isAttending, // asumiendo que isAttending contiene 'pendiente', 'confirmado', o 'noAsistira'
          pregunta1: song,
          pregunta2: message,
        }
      );
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setConfirmationMessage('Gracias por tu confirmación');

      // Limpia los campos después de unos segundos
      setTimeout(() => {
        setConfirmationMessage('');
        setName('');
        setIsAttending(null);
        setSong('');
        setMessage('');
        setCurrentIndex(currentIndex + 1);

        // set email, song, message y isAttending
        if (currentIndex + 1 < familyMembers.length) {
          const familyMember = familyMembers[currentIndex + 1];
          setEmail(familyMember.email);
          setSong(familyMember.pregunta1);
          setMessage(familyMember.pregunta2);
          setIsAttending(familyMember.estado);
        }

        if (currentIndex + 1 === familyMembers.length) {
          // Todos los invitados confirmados, reinicia el proceso
          setFamilyMembers([]);
          setCurrentIndex(0);
          setSelectedPerson(null);
          setName('');
          setGuestList([]);
        }
      }, 2500);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error al enviar el formulario de confirmación:', error);
    }
  };

  const handleSubmit = (e) => { e.preventDefault(); };

  return (
    <motion.div className='confirmacion-container'>
      <motion.img
        initial={{ opacity: 0 }}
        transition={{ duration: 1 }}
        animate={{ opacity: 1 }}
        exit={true ? { opacity: 0 } : {}}

        className='videoBg-mid'
        src={"/images/Project2/43-min.jpg"}
      />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={true ? { opacity: 0 } : {}}
        transition={{ duration: 1 }}
        className='bgOverlay-mid'>
      </motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={true ? { opacity: 0 } : {}}
        transition={{ duration: 1 }}
        className='section-information'>
        {isLoading && (
          <div className='loading-overlay'>
            <div className='loading-spinner'></div>
          </div>
        )}
        <span className='title-welcome normal-text'>Queremos que nos acompañes en este dia tan especial!</span>
        {confirmationMessage ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={true ? { opacity: 0 } : {}}
            transition={{ duration: 1 }}
            className='confirmation-process'
          >
            <span className='field-label center-text'>{confirmationMessage}</span >
          </motion.div>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={true ? { opacity: 0 } : {}}
            transition={{ duration: 1 }}
            className='confirmation-process'
          >
            {guestList.length === 0 ? (
              <>
                <p className='normal-parrafo'>
                  Ingresa tu PRIMER NOMBRE para verificar si estás en la lista de invitados.
                </p>
                <form className='confirmacion-form' onSubmit={handleSubmit}>
                  <div className='input-form'>
                    <label className='field-label' htmlFor='name'>Nombre</label>
                    <input id='name' className='input field-value ' type='text' value={name} onChange={(e) => setName(e.target.value)} autoComplete='off' />
                    <span className='error-message'>{searchByNameError}</span>
                    <button type='button' style={{ marginTop: '25px' }} onClick={searchByName}>Buscar</button>
                  </div>
                </form>
              </>
            ) : (familyMembers.length <= 0 && (
              <>
                <p className='normal-parrafo'>
                  Selecciona tu nombre de la lista de invitados.
                </p>
                <form className='confirmacion-form' onSubmit={handleSubmit}>
                  <div className='guest-list'>
                    {guestList.map((guest) => (
                      <>
                        <div key={guest.id} className='bottom-radio'>
                          <input
                            type='radio'
                            id={`guest-${guest.id}`}
                            name='guest'
                            value={guest.id}
                            checked={selectedPerson && selectedPerson.id === guest.id}
                            onChange={() => setSelectedPerson(guest)}
                            className='radio-input'
                          />
                          <label className='field-label radio-label' htmlFor={`guest-${guest.id}`}>{`${guest.nombre} ${guest.apellido}`}</label>
                        </div>
                      </>
                    ))}
                  </div>
                  <button type='button' onClick={handleContinue}>Continuar</button>
                </form>
              </>
            )
            )}
            {familyMembers.length > 0 && currentIndex < familyMembers.length && (
              <>
                <p className='field-label'>
                  Confirmacion para: <br /><span className='normal-text'> {`${familyMembers[currentIndex].nombre} ${familyMembers[currentIndex].apellido}`} </span>
                  {familyMembers[currentIndex].estado === 'confirmado' && <span className='font-red'>(Ya confirmado)</span>}
                </p>
                <form className='confirmacion-form' onSubmit={handleSubmit}>
                  <div className='radio-form'>
                    <br />

                  </div>

                  <div className='input-form'>
                    <label className='field-label' htmlFor='email'>Correo electrónico</label>
                    <input
                      id='email'
                      className='input field-value'
                      type='text'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder='Opcional'
                    />
                  </div>
                  <div className='textarea-form'>
                    <label className='field-label' htmlFor='song'>¿Te gustaria escuchar alguna cancion en la boda? ¿Cual?</label>
                    <textarea
                      id='song'
                      className='textarea field-value'
                      value={song}
                      onChange={(e) => setSong(e.target.value)}
                      placeholder='Opcional'
                      length='240'
                    />
                  </div>
                  <br /><br />
                  <div className='textarea-form'>
                    <label className='field-label' htmlFor='message'>¿Algun mensaje que nos quieras dar?</label>
                    <textarea
                      id='message'
                      className='textarea field-value'
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      placeholder='Opcional'
                      length='1000'
                    />
                  </div>
                  <br />
                  <br />
                  <label className='field-label radio-container'>
                    <span className='radio-label'>Asistiré</span>
                    <input
                      type='radio'
                      name='attendance'
                      value='confirmado'
                      onChange={() => setIsAttending('confirmado')}
                      checked={isAttending === 'confirmado'}
                      className='radio-input'
                    />
                  </label>
                  <br />
                  <label className='field-label radio-container radio-confirmation'>
                    <span className='radio-label'>No Asistiré</span>
                    <input
                      type='radio'
                      name='attendance'
                      value='noAsistira'
                      onChange={() => setIsAttending('noAsistira')}
                      checked={isAttending === 'noAsistira'}
                      className='radio-input'
                    />
                  </label>
                  <br />
                  <br />
                  <button type='button' onClick={handleConfirmation} aria-label='Enviar confirmación'>
                    Confirmar
                  </button>
                  &nbsp;&nbsp;
                  <button type='button' onClick={handleSkip} aria-label='Omitir confirmación'>
                    Omitir
                  </button>
                </form>
              </>
            )}
          </motion.div>
        )}
      </motion.div>
    </motion.div>
  );

};

export default Confirmacion;
