import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import './galeria.css';
import { projectService } from '../../data/projectService';
import { Gallery } from './Gallery';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { TfiAngleDown } from "react-icons/tfi";
import { MdArrowDropDownCircle } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { ContextProvider } from '../../contexts/ContextProvider';

export const Galeria = () => {
  const [project, setProject] = useState({});
  const params = useParams();
  const contentRef = useRef(null);
  const { t } = useTranslation();

  const { setHistoryLoaded, historyLoaded } = useContext(ContextProvider);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    //validate if home is in history loaded , si no esta lo agrega
    if (historyLoaded.galeria === undefined) {
      setHistoryLoaded({ ...historyLoaded, galeria: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [textRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: '-100px 0px', // Ajusta el margen según sea necesario
  });
  const textAnimation = useAnimation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setProject(projectService.getProjectById('pre_boda_min'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inView) {
      textAnimation.start('visible');
    }
  }, [inView, textAnimation]);

  const textVariants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
      },
    },
  };

  const scrollToBottom = () => {
    window.scrollTo({
      //top: document.documentElement.scrollHeight,
      top: contentRef.current.offsetTop,
      behavior: 'smooth',
    });
  };

  /*
  const handleScroll = () => {
    const contentSection = contentRef.current;
    const rect = contentSection.getBoundingClientRect();
    const quarterScreenHeight = window.innerHeight / 4;
    const isVisible = rect.top < window.innerHeight - quarterScreenHeight && rect.bottom >= quarterScreenHeight;
    contentSection.classList.toggle('show', isVisible);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);*/

  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      transition={{ delay: 0, duration: 2 }}
      className="main-page"
    >

      <section ref={contentRef} className={`content-galeria ${inView ? 'show' : ''}`}>
        <div className='galeria-description'>
          <div className='title'>
            <motion.h1
              className={`font-title darks`}
              ref={textRef}
              initial="hidden"
              animate={textAnimation}
              variants={textVariants}
            >
              Galeria
            </motion.h1>
          </div>

        </div>
        {project.images && <Gallery project={project} images={project.images} />}
      </section>
    </motion.div>
  );
};
