// Cronograma.js
import React from 'react';
import { motion } from 'framer-motion';
import './Cronograma.css';
import Maintenance from '../Maintenance';
// En las descripcion habra un texto emocilanl , alineado con el tutulo de cada event
const eventsData = [
  {
    hour: '03:00 PM',
    image: 'url_de_la_imagen_1',
    title: 'Bienvenida',
    description: '¡Queridos amigos y familiares, bienvenidos a nuestro día especial! Estamos llenos de emoción al dar inicio a esta jornada única. Que cada sonrisa y abrazo sea un reflejo del amor que compartimos con todos ustedes.',
  },
  {
    hour: '03:30 PM',
    image: 'url_de_la_imagen_2',
    title: 'Ceremonia',
    description: 'Nos comprometemos a amarnos y apoyarnos en cada paso del camino. Cada palabra pronunciada es una promesa eterna entre nosotros y Dios. Agradecemos a todos por ser testigos de este momento trascendental.',
  },
  {
    hour: '04:30 PM',
    image: 'url_de_la_imagen_3',
    title: 'Recepción',
    description: '¡Es hora de celebrar la alegría del amor! Compartiremos risas, musica y momentos inolvidables. Que este banquete sea un festín de felicidad para todos, ya que celebramos la unión de dos vidas que deciden estar juntas.',
  },
  {
    hour: '08:30 PM',
    image: 'url_de_la_imagen_4',
    title: 'Salida',
    description: 'Con corazones agradecidos, nos despedimos para embarcarnos en el viaje de la vida juntos. Que cada paso esté guiado por Dios, y que nuestro amor siga creciendo con cada amanecer. ¡Hasta que nos encontremos de nuevo, muchas gracias!',
  },
];

const Cronograma = () => {
  const fadeInVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <motion.div
      className="cronograma-container"
      initial="hidden"
      animate="visible"
      variants={fadeInVariants}
    >
      <h2 className="cronograma-titulo caveat-xxl">Cronograma</h2>
      {eventsData.map((evento, index) => (
        <motion.div
          key={index}
          className="evento"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: index * 0.2 }}
        >
          <div className="hora caveat caveat-l">{evento.hour}</div>
          <div className="titulo-evento caveat-xl">{evento.title}</div>
          <div className="descripcion-evento normal-text">{evento.description}</div>
          {index < eventsData.length - 1 && <hr className="linea-divisoria" />}
        </motion.div>
      ))}
    </motion.div>
  );
};

export default Cronograma;
