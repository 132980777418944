import React, { useEffect, useRef , useState} from 'react';
import { useParams } from 'react-router-dom';
import './Project.css';
import { projectService } from '../../data/projectService';
import { Gallery } from './Gallery';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { TfiAngleDown  } from "react-icons/tfi";
import { MdArrowDropDownCircle } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

export const Project = () => {
  const [project, setProject] = useState({});
  const params = useParams();
  const contentRef = useRef(null);
  const { t } = useTranslation();

  const [textRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: '-100px 0px', // Ajusta el margen según sea necesario
  });
  const textAnimation = useAnimation();

  useEffect(() => {
    setProject(projectService.getProjectById(params.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inView) {
      textAnimation.start('visible');
    }
  }, [inView, textAnimation]);

  const textVariants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
      },
    },
  };

  const scrollToBottom = () => {
    window.scrollTo({
      //top: document.documentElement.scrollHeight,
      top: contentRef.current.offsetTop,
      behavior: 'smooth',
    });
  };

  /*
  const handleScroll = () => {
    const contentSection = contentRef.current;
    const rect = contentSection.getBoundingClientRect();
    const quarterScreenHeight = window.innerHeight / 4;
    const isVisible = rect.top < window.innerHeight - quarterScreenHeight && rect.bottom >= quarterScreenHeight;
    contentSection.classList.toggle('show', isVisible);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);*/

  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      transition={{ delay: 0, duration: 2 }}
      className="main-page"
    >
      <div className='page-project'>
        <div className={`title-project font-title`}>
          <div className="btn-scroll-down" onClick={scrollToBottom}>
            <h1 className='font-title'>{project.name}</h1>
            <div>
              <div className="box-animate"><i className="fa fa-angle-down" aria-hidden="true"><TfiAngleDown/></i></div>
            </div>
          </div>
        </div>
      </div>
      <section ref={contentRef} className={`content-project ${inView ? 'show' : ''}`}>
        <div className='project-description'>
          <div className='title'>
            <motion.h1
              className={`font-title darks`}
              ref={textRef}
              initial="hidden"
              animate={textAnimation}
              variants={textVariants}
            >
              {project.name}
            </motion.h1>
          </div>
          <div className='information'>
            {project.project_small_information && project.project_small_information.map((info, index) => (
              <div className='data' key={index}>
                <span><b>{t("project."+Object.keys(info)[0])}:</b></span>
                <span>{Object.values(info)[0]}</span>
              </div>
            ))}
          </div>
        </div>
        {project.images && <Gallery project={project} images={project.images}/>}
      </section>
    </motion.div>
  );
};
