import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

const CountdownTimer = () => {
  // Asegúrate de establecer correctamente el año en la fecha de la boda
  const weddingDate = moment.tz('2024-03-16T15:00:00', 'America/Bogota'); // UTC-5
  const calculateTimeLeft = () => {
    const now = moment();
    const timeLeft = moment.duration(weddingDate.diff(now));
    return {
      days: timeLeft.days(),
      hours: timeLeft.hours(),
      minutes: timeLeft.minutes(),
      seconds: timeLeft.seconds(),
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [currentDateTime, setCurrentDateTime] = useState(moment().format('YYYY-MM-DD HH:mm:ss'));

  useEffect(() => {
    const timer = setInterval(() => {
      const timeLeftValue = calculateTimeLeft();
      setTimeLeft(timeLeftValue);
      setCurrentDateTime(moment().format('YYYY-MM-DD HH:mm:ss'));
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const fechaBoda = moment("2024-03-16T15:00:00-05:00");

  // Calculate the difference in seconds
  const countdownSeconds = fechaBoda.diff(moment().tz("America/Bogota"), "seconds");

  // Break down the difference into days, hours, minutes, and seconds
  const days = Math.floor(countdownSeconds / (60 * 60 * 24));
  const hours = Math.floor((countdownSeconds % (60 * 60 * 24)) / (60 * 60));
  const minutes = Math.floor((countdownSeconds % (60 * 60)) / 60);
  const seconds = countdownSeconds % 60;

  // Format the output string
  const formattedCountdown = `<span> ${days} </span>dias ${hours} horas ${minutes} min ${seconds} seg`;



  return (
    <div className='information'>
      <div className='caveat-l data '>
        <span className='count'>{days}</span>  <span className='text'>Dias</span>&nbsp;&nbsp;&nbsp;  <span className='count'>{hours}</span> <span className='text'>Horas</span>&nbsp;&nbsp; &nbsp; <span className='count'>{minutes}</span> <span className='text'>Minutos</span> &nbsp; &nbsp;&nbsp;<span className='count'>{seconds}</span> <span className='text'>Segundos</span>
      </div>
    </div>
  );
};

export default CountdownTimer;
