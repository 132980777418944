import React, { useContext, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { Contact } from '../components/contact/Contact';
import { About } from '../components/About';
import { Home } from '../components/home/Home';
import { Footer } from '../components/layout/Footer';
import { HeaderNav } from '../components/layout/HeaderNav';
import { Projects } from '../components/Projects';
import { Services } from '../components/Services';
import { Error } from '../components/Error';
import { Project } from '../components/project/Project';
import { ContextProvider } from '../contexts/ContextProvider';
import { Loader } from '../components/loader/Loader';
import { projectService } from '../data/projectService';
import { AnimatePresence } from 'framer-motion';
import Maintenance from '../components/Maintenance';
import { Inicio } from '../components/inicio/Inicio';
import { Sitio } from '../components/sitio/sitio';
import { Galeria } from '../components/galeria/galeria';
import { Confirmacion } from '../components/confirmacion/Confirmacion';
import Cronograma from '../components/cronograma/Cronograma';
import Recomendaciones from '../components/recomendaciones/Recomendaciones';

export const Routers = () => {
  const { setCurrentUrl, setModePage, loading, setLoading, setSrcBackground } = useContext(ContextProvider);
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;
    const urlParts = window.location.pathname.split('/');
    const projectId = urlParts[urlParts.length - 1];
    let project = projectService.getProjectById('Project2');

    if (currentPath === '/home' || true) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }


    if (currentPath === '/home2') {
      setCurrentUrl('home');
      setModePage('dark');
    } else if (currentPath === '/projects') {
      setCurrentUrl('projects');
      setModePage('light');
    } else if (currentPath.includes('/project/') || currentPath === '/home') {
      setCurrentUrl('home');
      setModePage('dark');
      if (project.video) {
        setSrcBackground(`video::/images/${project.id}/${project.video}`);
      } else {
        setSrcBackground(`/images/${project.id}/${project.cover_image}`);
      }
    } else if ((currentPath === '/contact')) {
      setCurrentUrl('contact');
      setModePage('light');
      //setSrcBackground(`/images/${project.id}/${project.cover_image}`);
    }
    else if ((currentPath === '/confirmacion')) {
      setCurrentUrl('confirmacion');
      setModePage('dark');
      //setSrcBackground(`/images/${project.id}/${project.cover_image}`);
    }
    else {
      setCurrentUrl('');
      setModePage('light');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps

    // Ajustar el scroll a la posición superior al cambiar de ruta
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>

      <>
        <HeaderNav />
        <ContentMemo location={location} loading={loading} setCurrentUrl={setCurrentUrl} />
        <Footer />
      </>


    </>
  );
};

const Content = ({ location, loading, setCurrentUrl }) => {
  return (
    <section className='content'>
      {/*
      <AnimatePresence initial={true} mode='wait'> 
        <Routes key={location.pathname} location={location}>
          <Route path='/' element={<Navigate to="/home" />} />
          <Route path='/home' element={loading ? <Loader /> : <Home />} />
          <Route path='/projects' element={loading ? <Loader /> : <Projects setCurrentUrl={setCurrentUrl} />} />
          <Route path='/services' element={loading ? <Loader /> : <Services />} />
          <Route path='/about' element={loading ? <Loader /> : <About />} />
          <Route path='/contact' element={loading ? <Loader /> : <Contact />} />
          <Route path='/project/:id' element={loading ? <Loader /> : <Project />} />
          <Route path='/*' element={loading ? <Loader /> : <Error />} />
        </Routes>
      </AnimatePresence>
      */}
      <AnimatePresence initial={true} mode='wait'>
        <Routes key={location.pathname} location={location}>
          <Route path='/' element={<Navigate to="/home" />} />
          <Route path='/home' element={loading ? <Inicio /> : <Inicio />} />
          <Route path='/cronograma' element={loading ? <Cronograma /> : <Cronograma />} />
          <Route path='/recomendaciones' element={loading ? <Recomendaciones /> : <Recomendaciones />} />
          {/*<Route path='/services' element={loading ? <Services /> : <Services />} />*/}
          <Route path='/sitio' element={loading ? <Sitio /> : <Sitio />} />
          <Route path='/galeria' element={loading ? <Galeria /> : <Galeria />} />
          <Route path='/confirmacion' element={loading ? <Confirmacion /> : <Confirmacion />} />
          <Route path='/project/:id' element={loading ? <Project /> : <Project />} />
          <Route path='/*' element={loading ? <Error /> : <Error />} />
        </Routes>
      </AnimatePresence>
    </section>
  );
};

const ContentMemo = React.memo(Content);
