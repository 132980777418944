import './App.css';
import { Routers } from './router/Routers';
import videoBg from './assets/video_background.mp4';
import { ContextProvider } from './contexts/ContextProvider';
import { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { projectService } from './data/projectService';
import { AnimatePresence } from 'framer-motion';

function App() {
  const [currentUrl, setCurrentUrl] = useState("");
  const [modePage, setModePage] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [srcBackground, setSrcBackground] = useState("");
  const [imageLoaded, setImageLoaded] = useState(false);

  //History component loaded
  const [historyLoaded, setHistoryLoaded] = useState({});

  const webState = {
    currentUrl,
    modePage,
    loading,
    historyLoaded,
    loading2,
    setCurrentUrl,
    setModePage,
    setLoading,
    setSrcBackground,
    setHistoryLoaded,
    setLoading2
  };

  useEffect(() => {
    // Obtener la ruta actual al cargar el componente
    const currentPath = window.location.pathname;
    const urlParts = window.location.pathname.split('/');
    const projectId = urlParts[urlParts.length - 1];
    let project = projectService.getProjectById('Project2');

    // Verificar si la ruta actual es "/home"
    if (currentPath === '/home2') {
      setCurrentUrl('home');
      setModePage('dark');
      setSrcBackground(videoBg);
    } else if (currentPath === '/projects') {
      setCurrentUrl('projects');
      setModePage('light');
    } else if (currentPath.includes('/project/') || currentPath === '/home') {
      setCurrentUrl('home');
      setModePage('dark');
      setLoading2(true);
      if (project.video) {
        setSrcBackground(`video::/images/${project.id}/${project.video}`);
      } else {
        setSrcBackground(`/images/${project.id}/${project.cover_image}`);
      }
      // Simular carga durante 3 segundos y luego establecer loading2 en false
      setTimeout(() => {
        setLoading2(false);
      }, 1000);
    } else if ((currentPath === '/contact')) {
      setCurrentUrl('contact');
      setModePage('dark');
    } else if ((currentPath === '/confirmacion')) {
      setCurrentUrl('confirmacion');
      setModePage('dark');
      setLoading2(true);
      setTimeout(() => {
        setLoading2(false);
      }, 1000);
    } else if ((currentPath === '/galeria')) {
      setCurrentUrl('contact');
      setModePage('dark');
      setLoading2(true);
      setTimeout(() => {
        setLoading2(false);
      }, 1000);
    } else {
      setCurrentUrl('');
      setModePage('light');
    }
  }, []);

  //useeffect for historyLoaded 
  useEffect(() => {
    setLoading2(true);
    console.log("historyLoaded");
    setTimeout(() => {
      setLoading2(false);
    }, 1000);
  }, [historyLoaded]);

  const handleImageLoad = () => {
    console.log("handleImageLoad");
    setLoading2(false);
  };

  return (
    <>
      <ContextProvider.Provider value={webState}>
        <AnimatePresence initial={true} mode='sync'>
          {currentUrl === "homes" && (
            <>
              <video className='videoBg' src={videoBg} autoPlay loop muted playsInline preload='auto' />
              <div className='bgOverlay'></div>
            </>
          )}
          {(currentUrl === "project" || currentUrl === "home") && (
            srcBackground.startsWith('video::') ?
              <>
                <video onLoad={handleImageLoad} className='videoBg' src={srcBackground.substring('video::'.length)} autoPlay loop muted playsInline preload='auto' disablePictureInPicture />
                <div className='bgOverlay'></div>
              </> :
              <>
                <img onLoad={() => setImageLoaded(true)} className='videoBg' src={srcBackground} />
                <div className='bgOverlay'></div>
                <div className='bgOverlay-before'></div>
              </>
          )}
          {currentUrl === "contact" && (
            <>
              <div className='bgOverlay bg-gray'></div>
            </>
          )}
          {loading2 && (
            <div className='loading-overlay'>
              <div className='loading-spinner'></div>
            </div>
          )}
          <div key={"locations-router"} className='layout'>
            <BrowserRouter>
              <Routers />
            </BrowserRouter>
          </div>
        </AnimatePresence>
      </ContextProvider.Provider>
    </>
  );
}

export default App;
