const projects = [
    {
        'id': 'Project1',
        'name': 'APTO TIERRA BELLA',
        "category": 'visualization',
        'cover_image': '01.principal.jpg',
        'project_small_information': [
            { 'area': '80 m2' },
            { 'year': '2023' },
            { 'visualization': 'LAO architects' },
            { 'location': 'Armenia, Colombia' },
            { 'design': 'by Luci Alba' },
        ],
        'video': 'habitacion Alba 31052023.mp4',
        'images': ['01.principal.jpg', '05.jpg', '03.jpg', '04.jpg', '02.jpg', '06.jpg', '07.jpg', '08.jpg', '09.jpg']
    },
    {
        'id': 'Project2',
        'name': 'Lau y Nico',
        "category": 'interior_design',
        'cover_image': '43-min.jpg',//list hasta 44 images
        'project_small_information': [
            { 'area': '90 m2' },
            { 'year': '2023' },
            { 'visualization': 'LAO architects' },
            { 'location': 'Pereira, Colombia' },
            { 'design': 'LAO architects' },
        ],
        //'images': ['01.principal.jpeg', '01.jpg', '02.jpg', '03.jpg', '04.jpg', '05.jpg', '06.jpg', '07.jpg', '08.jpg', '09.jpg', '10.jpg', '11.jpg', '12.jpg', '13.jpg', '14.jpg', '15.jpg', '16.jpg', '17.jpg', '18.jpg', '19.jpg', '20.jpg']
        // list images in this structure { 'image_name' : '01.jpg' }
    },
    {
        'id': 'pre_boda',
        'name': 'Pre Boda',
        "category": 'photography',
        'cover_image': '01.principal.jpg',//list hasta 44 images
        //'images': ['01.jpg', '02.jpg', '03.jpg', '04.jpg', '05.jpg', '06.jpg', '07.jpg', '08.jpg', '09.jpg', '10.jpg', '11.jpg', '12.jpg', '13.jpg', '14.jpg', '15.jpg', '16.jpg', '18.jpg', '19.jpg', '20.jpg', '21.jpg', '22.jpg', '23.jpg', '24.jpg', '25.jpg', '26.jpg', '27.jpg', '28.jpg', '29.jpg', '30.jpg', '31.jpg', '32.jpg', '33.jpg', '34.jpg', '35.jpg', '36.jpg', '37.jpg', '38.jpg', '39.jpg', '40.jpg', '41.jpg', '42.jpg', '43.jpg', '44.jpg']
        // list images in this structure { 'image_name' : '01.jpg' , 'blurHash': 'L5H2EC=PM+yV0g-mq.wG9c010J}I' }
        'images': [{ 'image_name': '01.jpg', 'blurHash': 'LEAA8jrpRkn$u6Vrj[WB.TROazjY' }, { 'image_name': '02.jpg', 'blurHash': 'L1BMGF~V^O026:~C~V~A?JNy03EM' }, { 'image_name': '03.jpg', 'blurHash': 'L796{=i[xZofB?-4jZWWcbnMafs:' }, { 'image_name': '04.jpg', 'blurHash': 'LM8qjNoyWBV[yZa}s:j[lBt7aeoL' }, { 'image_name': '05.jpg', 'blurHash': 'L98gjUXTxCWC%%SiNdS6Klxbt7oz' }, { 'image_name': '06.jpg', 'blurHash': 'L99t1AWEV[I@%%OtS5bcGJ-pxaoz' }, { 'image_name': '07.jpg', 'blurHash': 'L48:x1=x=?Ne}@R-I[jY5XbHD*%2' }, { 'image_name': '08.jpg', 'blurHash': 'L896,U?Fn#s.~9xaWCWBt6IqjFj@' }]

    },
    {
        'id': 'pre_boda_min',
        'name': 'Pre Boda',
        "category": 'photography',
        'cover_image': '43-min.jpg',//list hasta 44 images
        //'images': ['01.jpg', '02.jpg', '03.jpg', '04.jpg', '05.jpg', '06.jpg', '07.jpg', '08.jpg', '09.jpg', '10.jpg', '11.jpg', '12.jpg', '13.jpg', '14.jpg', '15.jpg', '16.jpg', '18.jpg', '19.jpg', '20.jpg', '21.jpg', '22.jpg', '23.jpg', '24.jpg', '25.jpg', '26.jpg', '27.jpg', '28.jpg', '29.jpg', '30.jpg', '31.jpg', '32.jpg', '33.jpg', '34.jpg', '35.jpg', '36.jpg', '37.jpg', '38.jpg', '39.jpg', '40.jpg', '41.jpg', '42.jpg', '43.jpg', '44.jpg']
        // list images in this structure { 'image_name' : '01.jpg' , 'blurHash': 'L5H2EC=PM+yV0g-mq.wG9c010J}I' }
        'images': [
            { 'image_name': '01-min.jpg', 'blurHash': 'LEAA8jrpRkn$u6Vrj[WB.TROazjY', 'width': '250', 'height': '500' },
            { 'image_name': '02-min.jpg', 'blurHash': 'L1BMGF~V^O026:~C~V~A?JNy03EM' },
            { 'image_name': '03-min.jpg', 'blurHash': 'L796{=i[xZofB?-4jZWWcbnMafs:' },
            { 'image_name': '04-min.jpg', 'blurHash': 'LM8qjNoyWBV[yZa}s:j[lBt7aeoL' },
            { 'image_name': '05-min.jpg', 'blurHash': 'L98gjUXTxCWC%%SiNdS6Klxbt7oz' },
            { 'image_name': '06-min.jpg', 'blurHash': 'L99t1AWEV[I@%%OtS5bcGJ-pxaoz' },
            { 'image_name': '07-min.jpg', 'blurHash': 'L48:x1=x=?Ne}@R-I[jY5XbHD*%2' },
            { 'image_name': '08-min.jpg', 'blurHash': 'L896,U?Fn#s.~9xaWCWBt6IqjFj@' },
            { 'image_name': '09-min.jpg', 'blurHash': 'L1BMGF~V^O026:~C~V~A?JNy03EM' },
            { 'image_name': '10-min.jpg', 'blurHash': 'L796{=i[xZofB?-4jZWWcbnMafs:' },
            { 'image_name': '11-min.jpg', 'blurHash': 'LM8qjNoyWBV[yZa}s:j[lBt7aeoL', 'width': '250', 'height': '100' },
            { 'image_name': '12-min.jpg', 'blurHash': 'L98gjUXTxCWC%%SiNdS6Klxbt7oz' },
            { 'image_name': '13-min.jpg', 'blurHash': 'L99t1AWEV[I@%%OtS5bcGJ-pxaoz' },
            { 'image_name': '16-min.jpg', 'blurHash': 'LEAA8jrpRkn$u6Vrj[WB.TROazjY' },
            { 'image_name': '18-min.jpg', 'blurHash': 'L796{=i[xZofB?-4jZWWcbnMafs:' },
            { 'image_name': '19-min.jpg', 'blurHash': 'LM8qjNoyWBV[yZa}s:j[lBt7aeoL' },
            { 'image_name': '20-min.jpg', 'blurHash': 'L98gjUXTxCWC%%SiNdS6Klxbt7oz' },
            { 'image_name': '21-min.jpg', 'blurHash': 'L99t1AWEV[I@%%OtS5bcGJ-pxaoz' },
            { 'image_name': '22-min.jpg', 'blurHash': 'L48:x1=x=?Ne}@R-I[jY5XbHD*%2' },
            { 'image_name': '23-min.jpg', 'blurHash': 'L896,U?Fn#s.~9xaWCWBt6IqjFj@' },
            { 'image_name': '24-min.jpg', 'blurHash': 'LEAA8jrpRkn$u6Vrj[WB.TROazjY' },
            { 'image_name': '25-min.jpg', 'blurHash': 'L1BMGF~V^O026:~C~V~A?JNy03EM' },
            { 'image_name': '26-min.jpg', 'blurHash': 'L796{=i[xZofB?-4jZWWcbnMafs:' },
            { 'image_name': '27-min.jpg', 'blurHash': 'LM8qjNoyWBV[yZa}s:j[lBt7aeoL' },
            { 'image_name': '28-min.jpg', 'blurHash': 'L98gjUXTxCWC%%SiNdS6Klxbt7oz' },
            { 'image_name': '29-min.jpg', 'blurHash': 'L99t1AWEV[I@%%OtS5bcGJ-pxaoz' },
            { 'image_name': '30-min.jpg', 'blurHash': 'L48:x1=x=?Ne}@R-I[jY5XbHD*%2' },
            { 'image_name': '33-min.jpg', 'blurHash': 'L1BMGF~V^O026:~C~V~A?JNy03EM' },
            { 'image_name': '37-min.jpg', 'blurHash': 'L99t1AWEV[I@%%OtS5bcGJ-pxaoz' },
            { 'image_name': '38-min.jpg', 'blurHash': 'L48:x1=x=?Ne}@R-I[jY5XbHD*%2' },
            { 'image_name': '39-min.jpg', 'blurHash': 'L896,U?Fn#s.~9xaWCWBt6IqjFj@' },
            { 'image_name': '40-min.jpg', 'blurHash': 'LEAA8jrpRkn$u6Vrj[WB.TROazjY' },
            { 'image_name': '41-min.jpg', 'blurHash': 'L1BMGF~V^O026:~C~V~A?JNy03EM' },
            { 'image_name': '42-min.jpg', 'blurHash': 'L796{=i[xZofB?-4jZWWcbnMafs:' },
            { 'image_name': '43-min.jpg', 'blurHash': 'LM8qjNoyWBV[yZa}s:j[lBt7aeoL', 'width': '250', 'height': '100' },
            { 'image_name': '44-min.jpg', 'blurHash': 'L98gjUXTxCWC%%SiNdS6Klxbt7oz' }
        ]

    },
    {
        'id': 'Project3',
        'name': 'Seren Bedroom',
        "category": 'interior_design',
        'cover_image': '01.principal.jpg',
        'project_small_information': [
            { 'area': '15 m2' },
            { 'year': '2023' },
            { 'visualization': 'LAO architects' },
            { 'location': 'Pereira, Colombia' },
            { 'design': 'LAO architects' },
        ],
        'video': 'habitacion 1 para landing page.mp4',
        'images': ['01.principal.jpg', '02.jpg', '03.jpg', '04.jpg', '06.jpg', '05.jpg', '07.jpg', '08.jpg']
    },
    {
        'id': 'Project4',
        'name': 'Bambusa',
        "category": 'architecture',
        'cover_image': '01.principal.jpg',
        'project_small_information': [
            { 'area': '200 m2' },
            { 'year': '2023' },
            { 'visualization': 'LAO architects' },
            { 'location': 'Pereira, Colombia' },
            { 'design': 'LAO architects' },
        ],
        'video': 'bambusa para landing page sin logo.mp4',
        'images': ['01.principal.jpg', '02.jpg', '03.jpg', '04.jpg', '06.jpg', '05.jpg', '07.jpg', '08.jpg', '09.jpg', '10.jpg', '11.jpg', '12.jpg', '13.jpg', '14.jpg']
    },
    {
        'id': 'Project5',
        'name': 'Alvora Apto',
        "category": 'interior_design',
        'cover_image': '01.principal.jpg',
        'project_small_information': [
            { 'area': '95 m2' },
            { 'year': '2023' },
            { 'visualization': 'LAO architects' },
            { 'location': 'Pereira, Colombia' },
            { 'design': 'LAO architects' },
        ],
        'video': 'sala comedor wabi  landing page.mp4',
        'images': ['01.principal.jpg', '02.jpg', '03.jpg', '06.jpg', '04.jpg', '07.jpg', '05.jpg', '08.jpg', '09.jpg', '10.jpg', '11.jpg', '12.jpg', '13.jpg', '14.jpg']
    },
    {
        'id': 'Project6',
        'name': 'MAR bedroom',
        "category": 'interior_design',
        'cover_image': '01.principal.jpg',
        'project_small_information': [
            { 'area': '20 m2' },
            { 'year': '2023' },
            { 'visualization': 'LAO architects' },
            { 'location': 'Pereira, Colombia' },
            { 'design': 'LAO architects' },
        ],
        'video': 'habitacion Mar para landing.mp4',
        'images': ['01.principal.jpg', '02.jpg', '03.jpg', '06.jpg', '04.jpg', '07.jpg', '05.jpg', '08.jpg', '09.jpg', '10.jpg', '11.jpg', '12.jpg', '13.jpg', '14.jpg', '15.jpg']
    },
    {
        'id': 'Project7',
        'name': 'Apto 305',
        "category": 'interior_design',
        'cover_image': '01.principal.png',
        'project_small_information': [
            { 'area': '145 m2' },
            { 'year': '2023' },
            { 'visualization': 'LAO architects' },
            { 'location': 'Pereira, Colombia' },
            { 'design': 'LAO architects' },
        ],
        'video': 'sala 1 para pagina web sin logo.mp4',
        'images': ['01.principal.png', '02.png', '03.png', '06.png', '04.png', '07.png', '05.png', '08.png', '09.png', '10.png', '11.png']
    }
]

export const projectService = {
    getAllProjects: () => {
        return projects;
    },
    getProjectsFilteredByCategory: (category) => {
        projects.filter((project) => {
            return project.category.toLowerCase().includes(category.toLowerCase());
        });
    },
    getProjectById: (projectId) => {
        return projects.find(project => project.id === projectId);
    }
};